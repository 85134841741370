<template>
  <div
    class="tree-select"
    :style="{
      'margin-bottom': hideDetails ? '0' : '30px'
    }">
    <v-menu
      v-model="treeMenu"
      :close-on-content-click="false"
      content-class="tree-select-menu"
      bottom
      offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div class="tree-select-wrap">
          <v-text-field
            class="tree-select--text-field"
            v-bind="attrs"
            :label="label"
            :value="textFieldValue"
            hide-details
            outlined
            readonly
            :rules="rules"
            :dense="dense"
            v-on="on">
            <template v-slot:append>
              <span
                class="tree-select-icon"
                v-bind="attrs"
                v-on="on">
                <v-icon>
                  mdi-menu-down
                </v-icon>
              </span>
            </template>
          </v-text-field>
        </div>
      </template>

      <tree-select-list
        v-model="inputValue"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        :sub-property="subProperty"
        return-object />
    </v-menu>
  </div>
</template>

<script>
import TreeSelectList from './TreeSelectList.vue'

export default {
  components: {
    TreeSelectList
  },
  props: {
    label: {
      type: String,
      default: 'Label'
    },
    items: {
      type: Array,
      default: () => []
    },
    itemText: {
      type: String,
      default: 'name'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    subProperty: {
      type: String,
      default: 'subList'
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      treeMenu: false
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (value) {
        this.treeMenu = false
        this.$emit('input', value)
      }
    },
    textFieldValue () {
      return this.inputValue && this.inputValue[this.itemText] ? this.inputValue[this.itemText] : ''
    }
  }
}
</script>

<style lang="scss">
.tree-select {
  .tree-select-wrap {
    position: relative;
    .tree-select-icon {
      .v-icon {
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
      }
    }
    .tree-select-icon[aria-expanded="true"] .v-icon {
      transform: rotate(180deg);
    }
  }
}
</style>
