<template>
  <v-list
    dense
    tile>
    <v-list-item-group
      v-for="(item, itemIndex) in items"
      :key="`tree-select-list-${itemIndex}`"
      class="">
      <v-list-group
        v-if="hasSub(item)"
        :ripple="false">
        <template v-slot:activator>
          <v-list-item-action>
            <v-checkbox
              v-model="inputValue"
              :off-icon="offIcon"
              :on-icon="onIcon"
              :false-value="null"
              :true-value="trueValue(item)"
              dense
              :disabled="item.disabled" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> {{ `${item[itemText]} (${item[subProperty].length})` }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          class="tree-select-sub-list-item"
          :ripple="false"
          inactive>
          <tree-select-list
            v-model="inputValue"
            class="tree-select-sub-list"
            :items="item[subProperty]"
            :item-text="itemText"
            :item-value="itemValue"
            :sub-property="subProperty"
            :multiple="multiple"
            :return-object="returnObject" />
        </v-list-item>
      </v-list-group>
      <v-list-item
        v-else
        :ripple="false"
        inactive>
        <v-list-item-action>
          <v-checkbox
            v-model="inputValue"
            :off-icon="offIcon"
            :on-icon="onIcon"
            :false-value="null"
            :true-value="trueValue(item)"
            dense
            :disabled="item.disabled" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item[itemText] }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'TreeSelectList',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemText: {
      type: String,
      default: 'name'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    subProperty: {
      type: String,
      default: 'subList'
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    offIcon () {
      return this.multiple ? 'mdi-checkbox-blank-outline' : 'mdi-radiobox-blank'
    },
    onIcon () {
      return this.multiple ? 'mdi-checkbox-marked' : 'mdi-radiobox-marked'
    }
  },
  methods: {
    hasSub (item) {
      const data = item[this.subProperty]
      return !!data && Array.isArray(data) && data.length > 0
    },
    trueValue (item) {
      return this.returnObject ? item : item[this.itemValue]
    }
  }
}
</script>

<style lang="scss" scoped>
.tree-select-sub-list {
  width: 100%;
}
</style>
